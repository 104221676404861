import {Controller} from "@hotwired/stimulus"
import {AniX} from "anix";
import {debounce} from "throttle-debounce";
import i18n from "../i18n";
import {previous} from "@tarekraafat/autocomplete.js/src/controllers/listController";

// Connects to data-controller="customer-location-form"
export default class extends Controller {
    static targets = ['deliveryConfigurationInputs']
    static values = {
        locationTypeConfigurations: Object
    }

    connect() {
    }

    deliveryConfigurationInputsTargetConnected(el) {
        console.log('delivery configuration inputs connected')
        console.log('locationTypeConfigurationsValue', this.locationTypeConfigurationsValue);
        this.element.querySelector('#customer_location_type')
            .addEventListener('change', this._updateDeliveryConfigurationInputs.bind(this));
        el.addEventListener('input', debounce(200, this._validateDeliveryConfiguration.bind(this)));
        el.addEventListener('change', debounce(200, this._validateDeliveryConfiguration.bind(this)));
        el.closest('form').addEventListener('submit', this._validateForm.bind(this));

    }

    _updateDeliveryConfigurationInputs(event) {
        const customerLocationType = event.target.value,
            previousCustomerLocationType = event.target.dataset.previousValue,
            deliveryConfigurationInputs = this.deliveryConfigurationInputsTarget.querySelectorAll('.delivery-input'),
            target_days_on_farm = this.deliveryConfigurationInputsTarget.querySelector('#customer_location_target_days_on_farm'),
            target_weight = this.deliveryConfigurationInputsTarget.querySelector('#customer_location_target_weight');

        if (customerLocationType.length && this.locationTypeConfigurationsValue[customerLocationType]) {
            if (this.deliveryConfigurationInputsTarget.classList.contains('d-none')) {
                AniX.fromTo(this.deliveryConfigurationInputsTarget, 0.33,
                    {opacity: 0}, {opacity: 1});
            }
            this.deliveryConfigurationInputsTarget.classList.remove('d-none');
            // Set the pre-configured target values, this is user editable
            if (target_days_on_farm.parentElement.querySelector('.invalid-feedback') || target_days_on_farm.value.length === 0 || customerLocationType !== previousCustomerLocationType) {
                target_days_on_farm.value = this.locationTypeConfigurationsValue[customerLocationType].target_days_on_farm;
            }
            if (target_weight.parentElement.querySelector('.invalid-feedback') || target_weight.value.length === 0 || customerLocationType !== previousCustomerLocationType) {
                target_weight.value = this.locationTypeConfigurationsValue[customerLocationType].target_weight;
            }
            // Enable all inputs
            deliveryConfigurationInputs.forEach(function (el) {
                el.disabled = false;
            });
        } else {
            AniX.fromTo(this.deliveryConfigurationInputsTarget, 0.33,
                {opacity: 1}, {
                    opacity: 0, onComplete: () => {
                        this.deliveryConfigurationInputsTarget.classList.add('d-none');
                        deliveryConfigurationInputs.forEach(function (el) {
                            el.disabled = true;
                        });
                    }
                });
        }

        // Revalidate the inputs
        deliveryConfigurationInputs.forEach(function (el) {
            this._validateInput(el);
        }.bind(this));
    }

    _validateDeliveryConfiguration(event) {
        const el = event.target;
        this._validateInput(el);
    }

    _validateInput(el) {
        const customerLocationType = this.element.querySelector('#customer_location_type').value,
            isMax = el.id.includes('_maximum_'),
            targetInput = isMax ? el.closest('.form-group').previousElementSibling.querySelector('input.delivery-input') : el,
            wasInvalid = el.classList.contains('is-invalid');

        el.classList.remove('border-danger', 'is-invalid', 'is-valid');
        el.closest('.form-group').querySelectorAll('.invalid-feedback, .is-invalid').forEach(function (el) {
            if (el.classList.contains('invalid-feedback')) {
                el.remove();
            } else {
                el.classList.remove('is-invalid');
            }
        });

        if (customerLocationType.length && this.locationTypeConfigurationsValue[customerLocationType]) {
            if (Number.isNaN(el.value)) {
                el.classList.add('is-invalid');
            } else {
                // Round all values to the nearest integer if they are not already
                if (el.value.length) {
                    el.value = Math.round(Number(el.value));
                }

                const min = Number(el.min),
                    max = Number(el.max),
                    value = Number(el.value);

                if (el.value.length && ((value < min || value > max) || (isMax && targetInput && targetInput.value.length && value < Number(targetInput.value)))) {
                    el.classList.add('is-invalid');
                } else if ((this.locationTypeConfigurationsValue[customerLocationType].target_days_on_farm || this.locationTypeConfigurationsValue[customerLocationType].target_weight) && (!isMax) && !el.value.length) {
                    el.classList.add('is-invalid');
                } else if (wasInvalid) {
                    el.classList.add('is-valid');
                }
            }

            // error message with range
            if (el.classList.contains('is-invalid')) {
                const small = document.createElement('small');
                small.classList.add('invalid-feedback');
                if ((isMax && targetInput && targetInput.value.length && Number(el.value) < Number(targetInput.value))) {
                    small.textContent = `[${targetInput.value} - ${el.max}]`;
                } else {
                    small.textContent = `[${el.min} - ${el.max}]`;
                }
                el.parentElement.append(small);
            } else if (!isMax) {
                this._validateInput(el.closest('.form-group').nextElementSibling.querySelector('input.delivery-input'));
            }
        }
    }

    _validateForm(event) {
        if (this.deliveryConfigurationInputsTarget.querySelectorAll('.is-invalid').length) {
            event.preventDefault();
            event.stopPropagation();
        }
    }
}

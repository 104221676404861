import Locale from "./locale";
import dayjs from "dayjs";
import localeData from 'dayjs/plugin/localeData'
import localizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(localizedFormat);
dayjs.extend(localeData);

// Load locales
require("./locales/day");

Locale.addListener(function (locale) {
    let [language, region, script] = locale.split('-');
    if (region && region.length >= 4) {
        [region, script] = [null, region]
    }
    let languageRegion = [language, region].filter(i => i != null).join('-').toLowerCase();
    if (languageRegion in dayjs.Ls) {
        dayjs.locale(languageRegion);
    } else if (language in dayjs.Ls) {
        dayjs.locale(language);
    } else {
        console.log(`Sorry! Cannot localize date / times into ${languageRegion}`)
        dayjs.locale('en');
    }
});
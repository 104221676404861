import AutocompleteSource from './autocomplete_source'
import i18n from "../../i18n";

export default class TransporterAutocompleteSource extends AutocompleteSource {
    constructor(options) {
        super('slaughterhouses', {
            keys: ['name'],
            ...options
        });
    }

    styleOptionNode(node, selection) {
        node.innerHTML = `<div class="text-wrap">${selection.match}</div>`;
        if (selection.value.full_address) {
            const address = document.createElement("address");
            address.classList.add('small', 'text-muted', 'm-0');
            address.textContent = selection.value.full_address;
            node.append(address);
        } else {
            const address = document.createElement("span");
            address.classList.add('small', 'text-muted', 'm-0');
            address.textContent = i18n.t('unknown_value', {value: i18n.t('activerecord.models.address', {count: 1})});
            node.append(address);
        }
    }

    styleFormattedValue(node, selection) {
        if (selection.value.full_address) {
            node.innerHTML = `<div class="d-flex align-items-center">
      <span class="w-100 text-truncate">${node.innerHTML}</span>
      <address class="flex-grow-1 text-truncate text-muted small mb-0 ml-2">
        ${selection.value.full_address}
      </address>
</div>`;
        }
    }
}

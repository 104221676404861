import Locale from "./locale";
import i18n from "./i18n";
import dayjs from "dayjs";

import isoWeek from 'dayjs/plugin/isoWeek'
dayjs.extend(isoWeek);

export default class DateRangeFormatter {
    static formatRelative(date, options) {
        if (date.className === "Moment") {
            date = dayjs(date)
        }
        if (dayjs().isSame(date, 'day')) {
            return i18n.t('date_range.today');
        } else if (dayjs().subtract(1, 'day').isSame(date, 'day')) {
            return i18n.t('date_range.yesterday');
        } else {
            const pattern = options && options.pattern ? options.pattern : 'LL';
            return date.format(pattern);
        }
    }

    static formatRelativeRange(begin, end, options) {
        if (begin.constructor.name === "Moment") {
            begin = dayjs(begin)
        }
        if (end.constructor.name === "Moment") {
            end = dayjs(end)
        }
        // If the range is the same day, just format the begin date
        if (begin.isSame(end, 'day')) {
            return this.formatRelative(begin);
        }

        // Check if the range is This [year|quarter|month] or Last [year|quarter|month]
        let thisOrLastPeriod;
        ['year', 'quarter', 'month'].find(unitOfTime => {
            if (begin.isSame(dayjs().startOf(unitOfTime), 'day') && (end.isSame(dayjs(), 'day') || end.isSame(dayjs().endOf(unitOfTime), 'day'))) {
                return thisOrLastPeriod = i18n.t('date_range.this_' + unitOfTime);
            } else if (begin.isSame(dayjs().subtract(1, unitOfTime).startOf(unitOfTime), 'day') && end.isSame(dayjs().subtract(1, unitOfTime).endOf(unitOfTime), 'day')) {
                return thisOrLastPeriod = i18n.t('date_range.last_' + unitOfTime);
            } else if (unitOfTime !== 'quarter' && begin.isSame(dayjs().subtract(1, unitOfTime).add(1, 'day').startOf('day')) && end.isSame(dayjs(), 'day')) {
                return thisOrLastPeriod = i18n.t('date_range.past_time_format', {time: i18n.t('date_range.' + unitOfTime + 's', {count: 1})});
            }
        });
        if (thisOrLastPeriod) {
            return thisOrLastPeriod;
        }

        // Check if the range is This week or Last week
        const weekStart = options && options.weekStart ? dayjs().isoWeekday(options.weekStart) : dayjs().startOf('week');
        if (weekStart.isAfter()) {
            weekStart.subtract(7, 'days');
        }
        if (begin.isSame(weekStart, 'day') && (end.isSame(dayjs(), 'day') || end.isSame(weekStart.clone().add(6, 'days'), 'day'))) {
            return i18n.t('date_range.this_week');
        } else if (begin.isSame(weekStart.clone().subtract(1, 'week'), 'day') && end.isSame(weekStart.clone().subtract(1, 'day'), 'day')) {
            return i18n.t('date_range.last_week');
        }

        if (dayjs().isSame(end, 'day') || dayjs().subtract(1, 'day').isSame(end, 'day')) {
            return `${begin.format('LL')} – ${this.formatRelative(end)}`;
        } else if (dayjs().isSame(begin, 'day') || dayjs().subtract(1, 'day').isSame(begin, 'day')) {
            return `${this.formatRelative(begin)} – ${end.format('LL')}`;
        } else {
            return this.formatRange(begin, end, options)
        }
    }

    static formatRange(begin, end, options) {
        if (begin.constructor.name === "Moment") {
            begin = dayjs(begin)
        }
        if (end.constructor.name === "Moment") {
            end = dayjs(end)
        }
        if (begin.isSame(end, 'day')) {
            // The same day, only format the begin date
            if (options && options.showTime) {
                if (begin.isSame(end, 'minute')) {
                    return begin.format('LLL');
                } else {
                    return `${begin.format('LL')} ${begin.format('LT')} – ${end.format('LT')}`;
                }
            } else {
                return begin.format('LL');
            }
        } else if (begin.isSame(begin.clone().startOf('month'), 'day') && end.isSame(begin.clone().endOf('month'), 'day')) {
            // The range is a full month
            return begin.format('MMMM YYYY');
        } else if (begin.isSame(begin.clone().startOf('quarter'), 'day') && end.isSame(begin.clone().endOf('quarter'), 'day')) {
            // The range is a full quarter
            return begin.format('[Q]Q YYYY');
        } else if (begin.isSame(begin.clone().startOf('year'), 'day') && end.isSame(begin.clone().endOf('year'), 'day')) {
            // The range is a full year
            return begin.format('YYYY');
        } else if (begin.isSame(begin.clone().startOf('year'), 'day') && end.isSame(end.clone().endOf('year'), 'day')) {
            // The range are multiple full years
            return `${begin.format('YYYY')} – ${end.format('YYYY')}`;
        } else if (begin.isSame(begin.clone().startOf('month'), 'day') && end.isSame(end.clone().endOf('month'), 'day')) {
            // The range are multiple full months
            const formattedBegin = begin.isSame(end, 'year') ? begin.format('MMMM') : begin.format('MMMM YYYY');
            return `${formattedBegin} – ${end.format('MMMM YYYY')}`;
        } else {

            // Format begin – end, removing year from begin if end is in the same year
            let formattedBegin = begin.format('LL');
            if (begin.isSame(end, 'year')) {
                formattedBegin = formattedBegin.replace(new RegExp('(, )?' + begin.format('YYYY') + '(, )?'), '');
            }
            return `${formattedBegin} – ${end.format('LL')}`;
        }
    }

    static formatPastDuration(begin) {
        if (begin.constructor.name === "Moment") {
            begin = dayjs(begin)
        }
        // The past X [years|quarters|months|weeks|days]
        let totalHours = Math.round(dayjs().endOf('day').diff(begin.clone().startOf('day'), 'hour', true));
        if (totalHours <= 48) {
            // Grouped by hour
            return i18n.t('date_range.past.hours', {count: totalHours});
        }

        let years, months = dayjs().diff(begin, 'month'),
            days = dayjs().diff(begin.clone().add(months, 'month'), 'day'),
            totalDays = dayjs().diff(begin, 'day');
        years = Math.floor(months / 12);
        months = months % 12;

        if (months === 0 && days === 0) {
            return i18n.t('date_range.past.years', {count: years});
        }
        if (years === 0 && days === 0) {
            return i18n.t('date_range.past.months', {count: months});
        }
        if (years === 0 && totalDays <= 90) {
            if (days !== 0 && totalDays > 7 && totalDays % 7 === 0) {
                return i18n.t('date_range.past.weeks', {count: totalDays / 7});
            } else {
                return i18n.t('date_range.past.days', {count: totalDays});
            }
        }

        let output = '';
        if (years !== 0) {
            output += new Intl.NumberFormat(Locale.get()).format(years) + ' ' + i18n.t('date_range.years', {count: years});
        }
        if (months !== 0) {
            if (output.length) {
                output += ' ';
            }
            output += new Intl.NumberFormat(Locale.get()).format(months) + ' ' + i18n.t('date_range.months', {count: months});
        }
        if (days !== 0) {
            if (output.length) {
                output += ' ';
            }
            output += new Intl.NumberFormat(Locale.get()).format(days) + ' ' + i18n.t('date_range.days', {count: days});
        }
        return i18n.t('date_range.past_time_format', {time: output});
    }
};
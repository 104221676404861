import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="notes"
export default class extends Controller {
    static targets = ['notesTable', 'emptyMessage', 'messageInput', 'messageForm', 'messageSendSubmit']

    connect() {
    }

    messageFormTargetConnected(el) {
        el.addEventListener('turbo:submit-end', (ev) => {
            if (ev.detail.success) {
                if (el.querySelector('.is-invalid')) {
                    // If the form contains errors, clear the errors and make sure the message input get reset to an
                    // empty value
                    el.querySelectorAll('.invalid-feedback').forEach(el => el.remove())
                    el.querySelectorAll('.is-invalid, .form-group-invalid')
                        .forEach(el => el.classList.remove('is-invalid', 'form-group-invalid'))
                    el.querySelectorAll('[aria-invalid]')
                        .forEach(el => el.removeAttribute('aria-invalid'))
                    this.messageInputTarget.defaultValue = "";
                }
                el.reset();
                this.updateSendButton(this.messageInputTarget.value);
            }
        })
    }

    notesTableTargetConnected(el) {
        this.tableObserver = new MutationObserver(() => {
            let hasNotes = el.querySelector('tr');
            el.classList.toggle('d-none', !hasNotes);
            this.emptyMessageTarget.classList.toggle('d-none', hasNotes);
        });
        this.tableObserver.observe(el, {attributes: false, childList: true, subtree: true});
    }

    notesTableTargetDisconnected(el) {
        this.tableObserver?.disconnect();
    }

    messageInputTargetConnected(el) {
        el.addEventListener('keydown', (ev) => {
            if (ev.keyCode === 13 && !(event.ctrlKey || event.metaKey || event.shiftKey)) {
                ev.preventDefault();
                if (el.value.match(/\S+/)) {
                    el.form.requestSubmit();
                }
                return false;
            }
        });

        el.addEventListener('input', () => this.updateSendButton(el.value));
        this.updateSendButton(el.value);
    }

    updateSendButton(message) {
        let hasMessage = message.match(/\S+/);
        this.messageSendSubmitTarget.disabled = !hasMessage
        this.messageSendSubmitTarget.classList.toggle('disabled', !hasMessage)
    }
}

import ChartHelper from "./charts";
import Rails from '@rails/ujs';
import moment from "moment";
import Locale from "./locale";

const DashboardWidgets = {};

DashboardWidgets.tooltips = {};
DashboardWidgets.tooltips.formatRangeTitle = function (data, formatterOptions) {
    if (data['rangeBegin'] && data['rangeEnd']) {
        let result = ChartHelper.format(data['rangeBegin'], formatterOptions);
        if (data['rangeBegin'] !== data['rangeEnd'] - 1) {
            result += " - " + ChartHelper.format(data['rangeEnd'], formatterOptions);
        }
        return result;
    } else if (data['rangeBegin']) {
        let begin = data['rangeBegin'];
        if (Number.isInteger(begin) && formatterOptions['type'] !== 'weight') {
            begin--;
        }
        return "> " + ChartHelper.format(begin, formatterOptions);
    } else if (data['rangeEnd']) {
        return "< " + ChartHelper.format(data['rangeEnd'], formatterOptions);
    } else {
        return data['x'];
    }
};

DashboardWidgets.tooltips.pigsFilterHandler = function (e, context, widgetType) {
    let $form = $('form.pigs-filter');
    if ($form.length) {
        if (context.chart.data.datasets.length > 1) {
            $form.find('#filter_data_set').val(context.dataSetIndex);
        } else if (context.chart.config.type !== 'line') {
            $form.find('#filter_data_set').val(context.dataIndex);
        }

        let dataItem = context.dataSet.data[context.dataIndex];
        switch (widgetType) {
            case 'history':
                let daterangepicker = $form.find('.form-group.filter_period_picker .btn-daterangepicker').data('daterangepicker'),
                    startDate = moment(dataItem.x, moment.ISO_8601), endDate,
                    $timeBeginInput = $form.find('#filter_time_begin'),
                    $timeEndInput = $form.find('#filter_time_end');
                switch (dataItem.unit) {
                    case 'hour':
                        endDate = startDate.clone().add(1, 'hour');
                        break;
                    case 'day':
                        endDate = startDate;
                        break;
                    case 'week':
                        endDate = startDate.clone().add(6, 'days');
                        break;
                    default:
                        endDate = startDate.clone().endOf(dataItem.unit);
                        break;
                }
                if ($timeBeginInput.length) {
                    $timeBeginInput.val(startDate.format('HH:mm'));
                }
                if ($timeEndInput.length && endDate !== endDate.clone().startOf('day')) {
                    $timeEndInput.val(endDate.format('HH:mm'));
                }
                // Atrocious
                if (daterangepicker) {
                    daterangepicker.setStartDate(startDate);
                    daterangepicker.setEndDate(endDate);
                    daterangepicker.calculateChosenLabel();
                    daterangepicker.callback(startDate.clone(), endDate.clone(), daterangepicker.chosenLabel);
                } else {
                    $timeEndInput.trigger('change');
                }
                break;
            case 'distribution':
                $('#filter_minimum').val(dataItem.rangeBegin);
                $('#filter_maximum').val(dataItem.rangeEnd);
                break;
        }

        Rails.fire($form.get(0), 'submit');
        $('#pigs').get(0).scrollIntoView({behavior: 'smooth'});
        ChartHelper.tooltip.toggleFixed(context.chart);
        e.preventDefault();
        return false;
    }
};

DashboardWidgets.formatPeriod = function (start, end, isHistory, localization) {
    let forceRelative = false;
    if (isHistory && end.isSame(moment(), 'day')) {
        let totalHours = Math.round(end.clone().endOf('day').diff(start.clone().startOf('day'), 'hour', true)),
            totalMonths = end.diff(start, 'month', true);
        if (totalHours >= 50 && totalMonths !== Math.floor(totalMonths)) {
            let totalDays = end.diff(start, 'day');
            if (totalDays <= 50) {
                // Grouped by day
                if (totalDays % 7 === 0 && totalDays > 7) {
                    return String.format(localization.pastTimeFormat, {time: new Intl.NumberFormat(Locale.get()).format(totalDays / 7) + ' ' + String.pluralize(totalDays / 7, localization.weeks)});
                } else {
                    return String.format(localization.pastTimeFormat, {time: new Intl.NumberFormat(Locale.get()).format(totalDays) + ' ' + String.pluralize(totalDays, localization.days)});
                }
            }

            let totalWeeks = end.clone().startOf('isoWeek').diff(start.clone().startOf('isoWeek'), 'day', true) / 7;
            if (totalWeeks <= 50) {
                // Grouped by week
                return String.format(localization.pastTimeFormat, {time: new Intl.NumberFormat(Locale.get()).format(Math.floor(totalWeeks)) + ' ' + String.pluralize(Math.floor(totalWeeks), localization.weeks)});
            }

            // Grouped by month
            start = start.clone().startOf('month');
            end = end.clone().startOf('month');
            forceRelative = true;
        }
    }
    return DateRangeHelper.formatRelative(start, end, localization, forceRelative);
};

ChartHelper.plugins['distributionWidget'] = {
    processOptions: function (options) {
        if (options.plugins == null) {
            options.plugins = {};
        }
        if (options.plugins.tooltip == null) {
            options.plugins.tooltip = {};
        }
        if (options.plugins.tooltip.callbacks == null) {
            options.plugins.tooltip.callbacks = {};
        }
        options.plugins.tooltip.callbacks.title = function (items) {
            return items.map(function (context) {
                let formatterOptions = ChartHelper.getFormatterOptions(context.chart, context.dataset, context.dataset.xAxisID || 'x');
                if (formatterOptions == null || formatterOptions.type == null) {
                    formatterOptions = {type: 'number'};
                }
                return DashboardWidgets.tooltips.formatRangeTitle(context.raw, formatterOptions);
            });
        }
    }
};
ChartHelper.plugins['pigsFilterTooltip'] = {
    processOptions: function (options) {
        let widgetType = $(this).attr('data-widget-display-type');
        if (widgetType && widgetType.length && options.plugins && options.plugins.tooltip) {
            options.plugins.tooltip.onItemClick = function (e, context) {
                DashboardWidgets.tooltips.pigsFilterHandler(e, context, widgetType);
            };
        } else {
            console.error('Widget type not specified (data-widget-display-type="history")');
        }
    }
};

module.exports = DashboardWidgets;
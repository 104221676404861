import {Controller} from "@hotwired/stimulus";

const ANCESTRY_METHOD_LINE_CODE = 'line_code';
const ANCESTRY_METHOD_MATRIX = 'matrix';

export default class extends Controller {
    static targets = ['ancestryMethodInputs', 'lineCodeInputs', 'matrixInputs']

    initialize() {
        this.updateAncestryInputs = this.updateAncestryInputs.bind(this)
    }

    connect() {
        this.updateAncestryInputs()
        this.ancestryMethodInputsTarget.querySelectorAll('input')
            .forEach(input => input.addEventListener('change', this.updateAncestryInputs))
    }

    updateAncestryInputs() {
        let ancestryMethod = this.ancestryMethodInputsTarget.querySelector('input:checked').getAttribute('value');
        this.lineCodeInputsTarget.classList.toggle('d-none', ancestryMethod != ANCESTRY_METHOD_LINE_CODE);
        this.matrixInputsTarget.classList.toggle('d-none', ancestryMethod != ANCESTRY_METHOD_MATRIX);
    }
}

//
// LeeO backoffice
//
import './jquery';
import './moment';
import './day';

import "@hotwired/turbo-rails";
import "./controllers";
import "./channels";

import "@fortawesome/fontawesome-pro/js/all";

FontAwesome.config.autoAddCss = false

import "./polyfill";
import Locale from "./locale";

window.Locale = Locale;
if (document.documentElement.lang) {
    Locale.set(document.documentElement.lang);
}

import Rails from '@rails/ujs';
import * as ActiveStorage from "@rails/activestorage"

Rails.start();
window.Rails = Rails;

ActiveStorage.start();

require("bootstrap");

// Bootstrap extensions / utils
require("bootstrap-daterangepicker/daterangepicker"); // Requires JQuery
window.bootbox = require('bootbox/bootbox'); // Requires JQuery

// ChartJS
require("chart.js/dist/chart");
require("chartjs-adapter-dayjs-3");
require("chartjs-plugin-annotation/dist/chartjs-plugin-annotation");

window.ChartHelper = require('./charts');

/*
Other libraries
*/
// Rich text editor
import "trix";
import "@rails/actiontext";
// Autocompleting text view
import "./typeahead"; // Requires JQuery
// Force input to match a pattern
require("jquery-mask-plugin/dist/jquery.mask"); // Requires JQuery
// Better time inputs
require("jquery-timepicker/jquery.timepicker.js"); // Requires JQuery
// Color picker
require("@claviska/jquery-minicolors/jquery.minicolors"); // Requires JQuery
// Sort HTML list by drag and drop
window.Sortable = require("sortablejs");
// Grab primary color from images
window.ColorThief = require("colorthief/dist/color-thief.umd");
// Copy to clipboard helper
window.ClipboardJS = require("clipboard/dist/clipboard");
// Show temporary message using snackbar pop-ups
window.Snackbar = require("./snackbar");
// Fuzzy search
window.Fuse = require('fuse.js');
// Keyboard shortcuts
require("mousetrap/mousetrap");
// Gesture detection
require("hammerjs/hammer");

// Throttle or debounce functions
require("./throttle-debounce-fn"); // Requires JQuery (Use `import {…} from "throttle-debounce"` in vanilla JS)

// Global assets
require("./global");
require("./string");
require("./auto-submit-form");
require("./select-all-checkbox");
window.DateRangeHelper = require("./date_range_helper");

// Scoped assets
window.PigFilter = require("./pig_filter");
window.DashboardConfigurator = require("./dashboard_configurator");
window.DashboardWidgets = require("./dashboard_widgets");
window.SelectColumns = require("./select_columns");
const {Chart} = require("chart.js");
const dayjs = require("dayjs");

var advancedFormat = require("dayjs/plugin/advancedFormat");
var duration = require("dayjs/plugin/duration");
var localizedFormat = require("dayjs/plugin/localizedFormat");
var relativeTime = require("dayjs/plugin/relativeTime");
var weekOfYear = require("dayjs/plugin/weekOfYear");

dayjs.extend(advancedFormat);
dayjs.extend(duration);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(weekOfYear);

const ChartHelper = {};
ChartHelper.defaultOptions = {
    maintainAspectRatio: false,
    hover: {
        mode: 'point', intersect: true
    },
};

ChartHelper.configuration = {};
ChartHelper.configuration.setLocale = function (locale) {
    ChartHelper.configuration['locale'] = locale;
};
ChartHelper.configuration.getLocale = function () {
    return ChartHelper.configuration['locale'] || navigator.language || navigator.userLanguage;
};

ChartHelper.load = function (container, options) {
    let $container, url, deferred;
    if (container instanceof jQuery) {
        $container = container;
    } else {
        $container = $(container);
    }
    if (!$container || !$container.length) {
        return
    }

    if (options && options.url) {
        url = options.url;
    } else {
        url = $container.attr('data-source');
    }
    if (url == null) {
        console.error('Url is undefined.');
        return;
    }

    if (options && options.deferred != null) {
        deferred = options.deferred;
    } else {
        deferred = $container.attr('data-deferred');
        deferred = deferred && deferred !== 'false';
    }
    if (deferred) {
        let canvas = $container.find('canvas').get(0),
            isInViewPort = function (canvas) {
                // The element's offsetParent property will return null whenever it, or any of its parents,
                // is hidden via the display style property ( http://stackoverflow.com/a/21696585 )
                if (!canvas || canvas.offsetParent === null) {
                    return false;
                }

                let rect = canvas.getBoundingClientRect();

                return rect.right >= 0
                    && rect.bottom >= 0
                    && rect.left <= window.innerWidth
                    && rect.top <= window.innerHeight;
            };
        if (canvas && !isInViewPort(canvas)) {
            let scrollListener = $.throttle(100, function () {
                if (isInViewPort(canvas)) {
                    document.removeEventListener('scroll', scrollListener, true);
                    ChartHelper.load($container, $.extend({}, options, {deferred: false}));
                }
            });
            document.addEventListener('scroll', scrollListener, true);
            return;
        }
    }

    $.ajax({url: url, dataType: 'json'})
        .done(function (configuration) {
            if (options && options.onLoaded) {
                options.onLoaded.call($container.get(0), configuration);
            }
            let chartType;
            if (options) {
                chartType = options.chartType;
            }
            if (configuration.noChart) {
                ChartHelper.showPlaceholder($container, configuration);
            } else {
                ChartHelper.updateChart($container, configuration, chartType, options && options.noAnimate);
            }
        })
        .fail(function (jqXHR, textStatus) {
            let errorMessage;
            if (options && options.loadingErrorMessage && options.loadingErrorMessage.length) {
                errorMessage = options.loadingErrorMessage;
            }
            if (!errorMessage) {
                errorMessage = textStatus;
            }
            if (!errorMessage || !errorMessage.length) {
                errorMessage = ' ';
            }
            ChartHelper.showPlaceholder($container, {error: errorMessage});
        });
};
ChartHelper.showPlaceholder = function (container, configuration) {
    let $container;
    if (container instanceof jQuery) {
        $container = container;
    } else {
        $container = $(container);
    }
    if (!$container || !$container.length) {
        return
    }
    let $placeholder = $container.find('.chart-placeholder'), $message = $('<span></span>'),
        icon = configuration.icon, text;
    if ($placeholder.length === 0) {
        $placeholder = $('<div></div>', {class: 'chart-placeholder'});
        $container.append($placeholder);
    }

    if (configuration.error && configuration.error.length) {
        if (icon == null) {
            icon = 'exclamation-circle';
        }
        text = configuration.error;
        $message.addClass('error');
    } else if (configuration.message && configuration.message.length) {
        text = configuration.message;
    }
    if (icon != null) {
        $message.append($('<i></i>', {class: 'fas fa-' + icon})).append(' ');
    }
    $message.append(text);
    $placeholder.html($message);
};
ChartHelper.updateChart = function (container, configuration, chartType, noAnimate) {
    let $container;
    if (container instanceof jQuery) {
        $container = container;
    } else {
        $container = $(container);
    }
    if (!$container || !$container.length) {
        return
    }

    let chart = $container.data('chart'),
        chartOptions = ChartHelper.processOptions($container, configuration.options),
        $placeholder = $container.find('.chart-placeholder');

    if (chart == null) {
        let canvas = $container.find('canvas').get(0);
        if (canvas == null) {
            console.error('Container does not contain canvas');
            return;
        }
        if (!chartType) {
            chartType = $container.attr('data-type');
        } else if ($.isFunction(chartType)) {
            chartType = chartType.call(chart);
        }
        if (!chartType || !chartType.length) {
            console.error('Chart type is undefined');
            return;
        }
        chart = new Chart(canvas, {
            type: chartType,
            data: configuration.data,
            options: chartOptions
        });
        $container.data('chart', chart);
    } else {
        if (configuration.data && configuration.data.datasets && configuration.data.datasets.length) {
            if (noAnimate || chart.data.datasets.length !== configuration.data.datasets.length) {
                chart.data.datasets = configuration.data.datasets;
                ChartHelper.tooltip.hide(chart);
            } else {
                $.each(configuration.data.datasets, function (index, dataset) {
                    let existingDataset = chart.data.datasets[index],
                        optionsChanged = false,
                        option;
                    for (option in dataset) {
                        if (option !== 'data' && dataset.hasOwnProperty(option)) {
                            if ($.isArray(dataset[option]) || $.isPlainObject(dataset[option])) {
                                if (JSON.stringify(existingDataset[option]) !== JSON.stringify(dataset[option])) {
                                    optionsChanged = true;
                                    break;
                                }
                            } else {
                                // noinspection EqualityComparisonWithCoercionJS
                                if (existingDataset[option] != dataset[option]) {
                                    optionsChanged = true;
                                    break;
                                }
                            }
                        }
                    }
                    if (optionsChanged) {
                        chart.data.datasets[index] = dataset;
                        ChartHelper.tooltip.hide(chart);
                    } else {
                        chart.data.datasets[index].data = dataset.data;
                    }
                });
            }
        } else {
            chart.data.datasets = [];
            ChartHelper.tooltip.hide(chart);
        }
        if (configuration.data && configuration.data.labels) {
            chart.data.labels = configuration.data.labels;
        } else if (chart.data.labels) {
            chart.data.labels = [];
        }
        $.extend(chart.options, chartOptions);
        if (noAnimate) {
            chart.update('none');
        } else {
            chart.update();
        }
    }
    if ($placeholder.length) {
        $placeholder.fadeOut('fast', function () {
            $placeholder.remove();
        });
    }
};

ChartHelper.processOptions = function ($container, options) {
    if (options == null) {
        return options;
    }

    options = $.extend(true, {}, ChartHelper.defaultOptions, options);
    if (options.plugins && options.plugins.tooltip && options.plugins.tooltip.external === true) {
        options.plugins.tooltip.enabled = false;
        if (options.plugins.tooltip.mode == null) {
            options.plugins.tooltip.mode = 'point';
        }
        if (options.plugins.tooltip.intersect == null) {
            options.plugins.tooltip.intersect = true;
        }
        options.plugins.tooltip.external = function (tooltip) {
            ChartHelper.tooltip.build(this._chart, tooltip);
        };
    }

    if (options.plugins && options.plugins.tooltip && (options.plugins.tooltip.external || options.plugins.tooltip.enabled)) {
        if (!(options.plugins.tooltip.callbacks && options.plugins.tooltip.callbacks.title)) {
            if (!options.plugins.tooltip.callbacks) {
                options.plugins.tooltip.callbacks = {};
            }

            options.plugins.tooltip.callbacks.title = function (items) {
                return items.map(function (context) {
                    let formatterOptions = ChartHelper.getFormatterOptions(context.chart, context.dataset, context.dataset.xAxisID || 'x');
                    if (formatterOptions) {
                        return ChartHelper.format(context.raw.x, formatterOptions);
                    } else {
                        return context.label;
                    }
                });
            };
        }
        if (!options.plugins.tooltip.callbacks.label) {
            options.plugins.tooltip.callbacks.label = function (context) {
                let label = context.dataset.label || context.raw.customLabel || context.label,
                    formatterOptions = ChartHelper.getFormatterOptions(context.chart, context.dataset, context.dataset.yAxisID || 'y'),
                    value = context.raw.y ?? context.raw

                if (formatterOptions) {
                    return [label, ChartHelper.format(value, formatterOptions)];
                } else {
                    return [label, value];
                }
            }
        }
    }

    if (options.scales) {
        $.each(options.scales, function (id, axis) {
            if (axis.formatter) {
                let type = axis.formatter.type,
                    formatterOptions = null,
                    formatter;

                if (type == null) {
                    type = 'number';
                }
                if (Object.keys(axis.formatter).length > 1) {
                    formatterOptions = $.extend({}, axis.formatter);
                    delete formatterOptions.type;
                }
                formatter = ChartHelper.valueFormatters[type];
                if (formatter) {
                    if (axis.ticks == null) {
                        axis.ticks = {};
                    }
                    axis.ticks.callback = function (label) {
                        if (formatterOptions) {
                            return formatter.call({context: 'ticks', axis: this}, label, formatterOptions);
                        } else {
                            return formatter.call({context: 'ticks', axis: this}, label);
                        }
                    };
                } else {
                    console.error('Formatter does not exist: ' + type);
                }
            }
        });
    }

    let pluginsAttr = $container.attr('data-plugins');
    if (pluginsAttr && pluginsAttr.length) {
        if (!options.helperPlugins) {
            options.helperPlugins = [];
        } else if (!$.isArray(options.helperPlugins)) {
            options.helperPlugins = [options.helperPlugins];
        }
        $.each(pluginsAttr.split(','), function (index, pluginName) {
            options.helperPlugins.push(pluginName.trim());
        });
    }
    if (options.helperPlugins) {
        let plugin, pluginHandler = function (index, pluginName) {
            plugin = ChartHelper.plugins[pluginName];
            if (plugin == null) {
                console.error('ChartHelper plugin not found: ' + pluginName);
            } else if (plugin.processOptions) {
                plugin.processOptions.call($container, options);
            }
        };
        if ($.isArray(options.helperPlugins)) {
            $.each(options.helperPlugins, pluginHandler);
        } else {
            pluginHandler(0, options.helperPlugins);
        }
    }

    return options;
};

ChartHelper.getFormatterOptions = function (chart, dataset, axisId) {
    if (dataset.formatter && dataset.formatter.type) {
        return dataset.formatter;
    }
    if (!chart.options.scales) {
        return null;
    }
    if (axisId == null) {
        axisId = 'y';
    }
    let axis = chart.options.scales[axisId],
        formatterOptions = null;
    if (axis == null) {
        throw `'${axisId}' axis not found`;
    }
    // Get formatter from axis
    if (axis.formatter) {
        formatterOptions = axis.formatter;
    } else if (axis.type === 'time') {
        formatterOptions = {
            type: 'time'
        };
        if (axis.time && axis.time.unit) {
            formatterOptions['unit'] = axis.time.unit;
        }
    }
    return formatterOptions;
};

ChartHelper.format = function (data, formatterOptions) {
    if (formatterOptions && formatterOptions.type && formatterOptions.type.length) {
        let formatter = ChartHelper.valueFormatters[formatterOptions.type], options;
        if (formatter != null) {
            if (Object.keys(formatterOptions).length > 1) {
                options = $.extend({}, formatterOptions);
                delete options.type;

                return formatter.call(this, data, options);
            } else {
                return formatter.call(this, data);
            }
        } else {
            console.error('Formatter does not exist: ' + formatterOptions.type);
            return data;
        }
    } else {
        return data;
    }
};


ChartHelper.tooltip = {};
ChartHelper.tooltip.build = function (context) {
    // Tooltip Element
    let tooltip = context.tooltip,
        options = context.options,
        parentNode = context.canvas.parentNode,
        tooltipElement = parentNode.querySelector('.chart-tooltip'),
        fixable = (options['fixable'] !== false);
    if (tooltipElement == null) {
        tooltipElement = document.createElement('div');
        tooltipElement.classList.add('chart-tooltip');
        parentNode.appendChild(tooltipElement)
    }
    if (fixable && context.config.options.onClick == null) {
        context.config.options.onClick = () => ChartHelper.tooltip.toggleFixed(context);
    }

    if (tooltipElement.classList.contains('chart-tooltip-fixed')) {
        // Don't mess with fixed tooltip
        return;
    }
    // Hide if no tooltip
    if (tooltip.opacity === 0) {
        tooltipElement.classList.remove('active')
        return;
    }

// Set Text
    if (tooltip.body) {
        let hasTitle = tooltip.title && tooltip.title.length && tooltip.title[0] && tooltip.title[0].length,
            innerHtml = '';

        if (fixable || hasTitle) {
            innerHtml += '<header class="tooltip-title">';
            if (hasTitle) {
                innerHtml += tooltip.title[0];
            }
            if (fixable) {
                innerHtml += '<span class="lock-icons"><i class="fas fa-lock"></i><i class="fas fa-unlock"></i></span>';
            }
            innerHtml += '</header>';
        }

        tooltip.body.forEach(function (body, i) {
            let dataset = context.data.datasets[tooltip.dataPoints[i].datasetIndex],
                colors = tooltip.labelColors[i],
                labelStyle = colors.backgroundColor ? 'border-left: 3px solid ' + colors.backgroundColor + '; padding-left: 4px;' : '',
                url = fixable ? dataset.tooltipItemUrl : null,
                bodyParts;
            if ($.isArray(body.lines) && body.lines.length === 2) {
                bodyParts = body.lines;
            } else if (typeof body.lines[0] === 'string' && body.lines[0].includes(': ')) {
                bodyParts = body.lines[0].split(': ');
            } else {
                bodyParts = ['', body.lines[0]];
            }
            if (url && url.length) {
                let dataPoint = tooltip.dataPoints[i],
                    dataItem = dataset.data[dataPoint.dataIndex];
                url = url.replace(/~\.([^~]+)~/g, function (match, variable) {
                    if (dataItem.hasOwnProperty(variable)) {
                        return dataItem[variable];
                    } else if (dataPoint.hasOwnProperty(variable)) {
                        return dataPoint[variable];
                    } else if (dataPoint.raw && dataPoint.raw.hasOwnProperty(variable)) {
                        return dataPoint.raw[variable];
                    } else {
                        console.error(`datapoint does not have variable '${variable}'`);
                        return '';
                    }
                });
                if (new URL(url, window.location.origin).toString() === new URL(window.location.pathname, window.location.origin).toString()) {
                    // No need to make a link to the current page
                    url = null;
                }
            }
            if (url && url.length) {
                innerHtml += '<a class="tooltip-item" href="' + url + '" data-set-index="' + tooltip.dataPoints[i].datasetIndex + '" data-index="' + tooltip.dataPoints[i].dataIndex + '">';
            } else {
                innerHtml += '<div class="tooltip-item">';
            }
            innerHtml += '<span class="tooltip-label" style="' + labelStyle + '">' + bodyParts[0] + '</span>';
            innerHtml += '<span class="tooltip-value">' + bodyParts[1] + '</span>';
            if (url && url.length) {
                innerHtml += '<i class="fas fa-chevron-right"></i></a>';
            } else {
                innerHtml += '</div>';
            }
        });
        if (tooltip.footer && tooltip.footer.length) {
            innerHtml += '<footer class="tooltip-footer">';
            tooltip.footer.forEach(function (footer, i) {
                innerHtml += '<p>' + footer + '</p>';
            });
            innerHtml += '</div>';
        }
        tooltipElement.innerHTML = innerHtml;
        if (context.config.options.plugins.tooltip.onItemClick) {
            tooltipElement.querySelectorAll('a.tooltip-item').forEach((link) => {
                link.addEventListener('click', function (e) {
                    let dataSetIndex = link.dataset.setIndex, dataIndex = link.dataset.index;
                    return context.config.options.plugins.tooltip.onItemClick.call(context, e, {
                        chart: context,
                        dataIndex: dataIndex,
                        dataSet: context.config.data.datasets[dataSetIndex],
                        dataSetIndex: dataSetIndex
                    });
                });
            });
        }
        if (fixable) {
            tooltipElement.querySelector('.lock-icons').addEventListener(
                'click', () => ChartHelper.tooltip.toggleFixed(context)
            );
        }
    }

    let positionY = context.canvas.offsetTop,
        positionX = context.canvas.offsetLeft;

// Set font properties
    Object.assign(tooltipElement.style, {
        left: positionX + tooltip.caretX + 'px',
        top: positionY + tooltip.caretY + 'px',
        fontFamily: tooltip._bodyFontFamily ?? null,
        fontSize: tooltip.bodyFontSize + 'px'
    });

// Show
    tooltipElement.classList.add('active')
}
;
ChartHelper.tooltip.toggleFixed = function (chart, forceFixed) {
    let parentNode = chart.canvas.parentNode,
        tooltip = parentNode.querySelector('.chart-tooltip');
    if (!tooltip) {
        console.error("toggleFixed called without tooltip element");
        return;
    }

    if (forceFixed === false || tooltip.classList.contains('chart-tooltip-fixed')) {
        tooltip.classList.remove('chart-tooltip-fixed');

        // Rebuild the tooltip from the current cursor position
        ChartHelper.tooltip.build(chart);
    } else if (tooltip.classList.contains('active') && forceFixed !== false) {
        tooltip.classList.add('chart-tooltip-fixed');
    }
};
ChartHelper.tooltip.hide = function (chart) {
    let parentNode = chart.canvas.parentNode,
        tooltip = parentNode.querySelector('.chart-tooltip');
    if (tooltip) {
        tooltip.classList.remove('active', 'chart-tooltip-fixed');
    }
};

ChartHelper.valueFormatters = {};
ChartHelper.valueFormatters.number = function (value) {
    if (value == null || value === '') {
        return null;
    }
    return new Intl.NumberFormat(ChartHelper.configuration.getLocale()).format(value);
};
ChartHelper.valueFormatters.percentage = function (value) {
    if (value == null || value === '') {
        return null;
    }
    return new Intl.NumberFormat(ChartHelper.configuration.getLocale(), {
        style: 'percent',
        maximumFractionDigits: 1
    }).format(value / 100.0);
};
ChartHelper.valueFormatters.weight = function (value, options) {
    if (value == null || value === '') {
        return null;
    }
    if (options && (options.uom === 'imperial' || options.unit === 'lbs' || options.unit === 'lb')) {
        if (options.unit === 'gr') {
            value = value / 453.59237;
        } else if (options.unit === 'kg') {
            value = value / 0.45359237;
        }
        return new Intl.NumberFormat(ChartHelper.configuration.getLocale(), {maximumFractionDigits: 2}).format(value) + ' lbs';
    } else {
        if (options && options.unit === 'kg') {
            value = value * 1000;
        }
        if (value < 1000) {
            return new Intl.NumberFormat(ChartHelper.configuration.getLocale()).format(value) + ' gr';
        } else {
            return new Intl.NumberFormat(ChartHelper.configuration.getLocale(), {maximumFractionDigits: 2}).format(value / 1000) + ' kg';
        }
    }
};
ChartHelper.valueFormatters.age = function (value) {
    if (value == null || value === '') {
        return null;
    }

    return dayjs.duration(Math.round(value), "days").humanize(false);
};
ChartHelper.valueFormatters.money = function (value, options) {
    if (value == null || value === '') {
        return null;
    }
    let formatterOptions;
    if (options && options.currency && options.currency.length) {
        formatterOptions = {
            style: 'currency',
            currency: options.currency
        }
    } else {
        formatterOptions = {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }
    }
    return new Intl.NumberFormat(ChartHelper.configuration.getLocale(), formatterOptions).format(value);
};
ChartHelper.valueFormatters.slaughterRating = function (value) {
    if (value == null || value === '') {
        return null;
    }
    let stars = 12 * value - 8.4;
    if (this.context && this.context === 'ticks') {
        stars = Math.round(stars);
    }
    stars = stars / 2.0;

    if (this.context && this.context === 'ticks' && (stars < 0 || stars > 3)) {
        return null;
    }
    if (stars <= 0) {
        // Prevent '-0'
        stars = 0;
    }
    return new Intl.NumberFormat(ChartHelper.configuration.getLocale(), {maximumFractionDigits: 1}).format(stars) + ' ★';
};
ChartHelper.valueFormatters.millimeters = function (value) {
    if (value == null || value === '') {
        return null;
    }
    return new Intl.NumberFormat(ChartHelper.configuration.getLocale()).format(value) + ' mm';
};
ChartHelper.valueFormatters.time = function (value, options) {
    if (value == null || value === '') {
        return null;
    }
    let format = null;
    if (options) {
        if (options.format && options.format.length) {
            format = options.format;
        } else if (options.unit && options.unit.length) {
            switch (options.unit) {
                case 'year':
                    format = 'YYYY';
                    break;
                case 'month':
                    format = dayjs.localeData().longDateFormat('LL').replace(new RegExp(",? ?D+,? ?"), '');
                    break;
                case 'week':
                    format = `[W]w YYYY (${dayjs.localeData().longDateFormat('LL').replace(new RegExp(",? ?Y+,? ?"), '')})`;
                    break;
                case 'day':
                    format = 'LL';
                    break;
                case 'hour':
                    format = 'lll';
                    break;
            }
        }
        if (format == null) {
            console.error('Could not determine format from time formatter options: ' + options);
        }
    }
    if (format) {
        return dayjs(value).format(format);
    } else {
        return value;
    }
};

ChartHelper.plugins = {};

module.exports = ChartHelper;
import {Controller} from "@hotwired/stimulus"
import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime'

// Connects to data-controller="local-time"
export default class extends Controller {
    static values = {
        iso8601: String,
        relative: Boolean
    }

    initialize() {
        dayjs.extend(relativeTime);
    }

    disconnect() {
        if (this.currentTimer) {
            clearTimeout(this.currentTimer)
        }
    }

    iso8601ValueChanged() {
        this.formatTimestamp(this.element, dayjs(this.iso8601Value))
    }

    relativeValueChanged() {
        this.formatTimestamp(this.element, dayjs(this.iso8601Value))
    }

    formatTimestamp(element, timestamp) {
        if (this.currentTimer) {
            clearTimeout(this.currentTimer)
        }
        if (this.relativeValue && timestamp.isAfter(dayjs().subtract(7, 'days'))) {
            element.textContent = timestamp.fromNow();
            element.setAttribute('title', timestamp.format('LLL'))
            // Refresh the timestamp when it becomes stale
            let ago = timestamp.diff(dayjs(), 's');
            if (ago > 3600) {
                this.currentTimer = setTimeout(() => this.formatTimestamp(element, timestamp), 900_000);
            } else if (ago > 60) {
                this.currentTimer = setTimeout(() => this.formatTimestamp(element, timestamp), 60_000);
            } else {
                this.currentTimer = setTimeout(() => this.formatTimestamp(element, timestamp), 1000);
            }
        } else {
            element.textContent = timestamp.format('LLL');
            element.removeAttribute('title')
        }
    }
}

import {Controller} from "@hotwired/stimulus";

const TARGET_ATTR = 'data-clickable-table-target',
    PRIMARY_ACTION_ATTR_VALUE = 'primaryAction';

export default class extends Controller {

    connect() {
        this.element.classList.add('table-hover');
        this.cachePrimaryActions();
        this.element.querySelectorAll('tbody tr').forEach(row => {
            let mouseDownX, mouseDownY;
            row.addEventListener('mousedown', e => {
                mouseDownX = e.clientX;
                mouseDownY = e.clientY;
            }, {passive: true});
            row.addEventListener('click', e => {
                const primaryAction = this.primaryActions.get(row);
                if (primaryAction == null || e.target === primaryAction || this.isAction(e.target, row) ||
                    event.ctrlKey || event.metaKey) {
                    return;
                }
                // Check if the pointer has been moved too much
                let hasMoved = (mouseDownX && Math.abs(mouseDownX - e.clientX) > 5) ||
                    (mouseDownY && Math.abs(mouseDownY - e.clientY) > 5);
                mouseDownX = null;
                mouseDownY = null;
                if (hasMoved) {
                    return;
                }
                primaryAction.click();
            }, {passive: true});
            row.addEventListener('mouseover', e => {
                const primaryAction = this.primaryActions.get(row),
                    isOverAction = this.isAction(e.target, row);
                if (primaryAction && !isOverAction) {
                    primaryAction.classList.add('active');
                }
                this.element.querySelectorAll('a.active, button.active, input.active').forEach(el => {
                    if (isOverAction || el !== primaryAction) {
                        el.classList.remove('active');
                    }
                });
            }, {passive: true});
            row.addEventListener('mouseleave', () => {
                const primaryAction = this.primaryActions.get(row);
                if (primaryAction) {
                    primaryAction.classList.remove('active');
                }
            }, {passive: true});
        })
    }

    findPrimaryAction(row) {
        let actions = row.querySelectorAll(`[${TARGET_ATTR}="${PRIMARY_ACTION_ATTR_VALUE}"]`);
        if (actions.length > 0) {
            if (actions.length > 1) {
                console.warn('Multiple primary actions found in ', row);
            }
            return actions[0];
        }
        actions = row.querySelectorAll('a, button')
        if (actions.length > 1) {
            console.warn('Multiple actions found in ', row);
        }
        return actions.length === 1 ? actions[0] : null;
    }

    isAction(el, row) {
        if (el.nodeName === 'A' || el.nodeName === 'BUTTON' || el.nodeName === 'INPUT' ||
            el.getAttribute(TARGET_ATTR) === PRIMARY_ACTION_ATTR_VALUE) {
            return true;
        } else {
            let parent = el.parentElement;
            if (parent == null || parent === row) {
                return false;
            } else {
                return this.isAction(parent, row);
            }
        }
    }

    cachePrimaryActions() {
        const primaryActions = new Map()
        this.element.querySelectorAll('tbody tr').forEach(row => {
            const primaryAction = this.findPrimaryAction(row);
            primaryActions.set(row, primaryAction);
            row.classList.toggle('clickable', primaryAction != null);
        });
        this.primaryActions = primaryActions;
    }
}
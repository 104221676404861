import {Controller} from "@hotwired/stimulus"
import dayjs from "dayjs";
import i18n from "../i18n";
import {debounce, throttle} from "throttle-debounce";

// Connects to data-controller="pig-batch-charts"
export default class extends Controller {
    static targets = ['distributionWeekNumberSlider', 'selectedDistributionWeekNumber',
        'selectedDistributionPeriod', 'historyChart', 'distributionChart', 'individualHistoryChartToggle',
        'penHistoryChartToggle', 'individualDistributionChartToggle', 'penDistributionChartToggle',
        'randomPigSelectionHint']
    static values = {
        beginAt: String,
        endAt: String,
        weekNumbers: Array,
        historySource: String,
        distributionSource: String
    }

    connect() {
        // Debounce with a small delay so the change event can supersede the click event,
        let debouncedIndividualHistoryChart = debounce(50, (ev) => {
            if (ev.target.checked) {
                this._loadHistoryChart(ev.type === 'change', false);
            }
        });
        if (this.hasRandomPigSelectionHintTarget) {
            // Allow user to reload the chart if showing random pig selection
            //  Debounce with a larger delay to throttle click requests
            this.individualHistoryChartToggleTarget.addEventListener('click', debounce(
                1000,
                debouncedIndividualHistoryChart,
                {atBegin: true}
            ));
        }
        if (this.hasPenHistoryChartToggleTarget) {
            this.individualHistoryChartToggleTarget.addEventListener('change', debouncedIndividualHistoryChart);
            this.penHistoryChartToggleTarget.addEventListener('change', (ev) => {
                if (ev.target.checked) {
                    this._loadHistoryChart(true, true);
                }
            });
        }
        if (this.hasPenDistributionChartToggleTarget) {
            this.individualDistributionChartToggleTarget.addEventListener('change', (ev) => {
                if (ev.target.checked) {
                    this._loadDistributionChart(true, false, this._getSelectedWeekNumber());
                }
            });
            this.penDistributionChartToggleTarget.addEventListener('change', (ev) => {
                if (ev.target.checked) {
                    this._loadDistributionChart(true, true, this._getSelectedWeekNumber());
                }
            });
        }

        this._loadHistoryChart(
            true,
            this.hasPenHistoryChartToggleTarget && this.penHistoryChartToggleTarget.checked
        );
        this._loadDistributionChart(
            true,
            this.hasPenDistributionChartToggleTarget && this.penDistributionChartToggleTarget.checked,
            this._getSelectedWeekNumber()
        );
    }

    individualHistoryChartToggleTargetConnected(el) {
        // Fix restored states
        if (el.hasAttribute('checked') && !el.checked) {
            el.removeAttribute('checked')
        }
    }

    individualDistributionChartToggleTargetConnected(el) {
        // Fix restored states
        if (el.hasAttribute('checked') && !el.checked) {
            el.removeAttribute('checked')
        }
    }

    distributionWeekNumberSliderTargetConnected(el) {
        this._showSelectedWeek(this.weekNumbersValue[el.value - 1]);
        el.addEventListener('change', throttle(500, () => {
            let weekNumber = this.weekNumbersValue[el.value - 1];
            this._loadDistributionChart(
                false,
                this.hasPenDistributionChartToggleTarget && this.penDistributionChartToggleTarget.checked,
                weekNumber
            );
            this._showSelectedWeek(weekNumber);
        }));
    }

    _getSelectedWeekNumber() {
        let selectedIndex
        if (this.hasDistributionWeekNumberSliderTarget) {
            selectedIndex = this.distributionWeekNumberSliderTarget.value - 1;
        } else {
            selectedIndex = this.weekNumbersValue.size - 1;
        }
        return this.weekNumbersValue[selectedIndex];
    }

    _loadHistoryChart(showPlaceholder, groupByPen) {
        let chartElement = this.historyChartTarget;
        if (showPlaceholder) ChartHelper.showPlaceholder(chartElement, {icon: 'spinner fa-spin'});

        let uri = new URL(this.historySourceValue, window.location)
        uri.searchParams.set('group_by_pen', groupByPen)
        chartElement.dataset.source = uri;

        ChartHelper.load(chartElement, {
            onLoaded: () => {
                if (this.hasRandomPigSelectionHintTarget && !groupByPen) {
                    this.randomPigSelectionHintTarget.classList.remove('d-none')
                }
            }
        });
        if (this.hasRandomPigSelectionHintTarget && groupByPen) {
            this.randomPigSelectionHintTarget.classList.add('d-none')
        }
    }

    _loadDistributionChart(showPlaceholder, groupByPen, week) {
        let chartElement = this.distributionChartTarget;
        if (showPlaceholder) ChartHelper.showPlaceholder(chartElement, {icon: 'spinner fa-spin'});

        let uri = new URL(this.distributionSourceValue, window.location)
        uri.searchParams.set('group_by_pen', groupByPen)
        uri.searchParams.set('week', week)
        chartElement.dataset.source = uri;

        ChartHelper.load(chartElement);
    }

    _showSelectedWeek(weekNumber) {
        let beginAt = dayjs(this.beginAtValue).startOf('week').add(weekNumber - 1, 'weeks'),
            endAt = beginAt.add(7, 'd'),
            formattedEnd;
        if (endAt.isAfter(dayjs().startOf('day'))) {
            formattedEnd = i18n.t('date_range.today')
        } else {
            formattedEnd = endAt.format('LL');
        }
        this.selectedDistributionWeekNumberTarget.textContent = weekNumber
        this.selectedDistributionPeriodTarget.textContent = `${beginAt.format('LL')} - ${formattedEnd}`;
    }

}
